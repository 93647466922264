<template>
  <div>
    <div style="margin:15px 0;background:#fff;padding:10px">
      <a-form layout="inline">
        <a-form-item label="" style="width: 13%">
          <a-date-picker v-model:value="searchForm.pushStartTime" style="width: 100%" @change="startChange" allowClear
            placeholder="跟单开始时间" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-date-picker v-model:value="searchForm.pushEndTime" style="width: 100%" @change="endChange" allowClear
            placeholder="跟单结束时间" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.documentaryType" style="width: 100%" placeholder="跟单类型" allowClear>
            <a-select-option :value="1">普通跟进</a-select-option>
            <a-select-option :value="2">时效异常</a-select-option>
            <a-select-option :value="3">质损跟进</a-select-option>
            <a-select-option :value="4">目的地异常</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.documentaryStatus" style="width: 100%" placeholder="异常状态" allowClear>
            <a-select-option :value="1">未完结</a-select-option>
            <a-select-option :value="9">已完结</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.orgId" placeholder="选择部门" allowClear>
            <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id">{{ item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.salesman" placeholder="业务员" allowClear />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.vinNo" placeholder="车牌号" allowClear />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.transStatus" :options="truckStatus" style="width: 100%" placeholder="运输状态"
            allowClear>
          </a-select>
        </a-form-item>
        <a-space>
          <div class="operate-btn">
            <a-button class="header-search-btn" @click="search" :loading="loading">搜索</a-button>
            <a-button class="header-reset-btn" @click="reset" :loading="loading">重置</a-button>
          </div>
        </a-space>
      </a-form>
    </div>
    <div style="padding:10px;background:#fff">
      <div style="margin-bottom:10px;">共<span style="color:#0066CC">{{ pagination.total }}</span>条数据</div>
      <a-table size="small" :columns="columns" :rowKey="(record, index) => { return index }" :data-source="listData"
        bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #sort="{ index }">
          {{ index + 1 }}
        </template>
        <template #brand="{ record }">
          <div>
            <span style="margin-right:10px">{{ record.vinNo }}</span>
            <span>{{ record.brand + record.model }}</span>
          </div>
        </template>
        <template #transStatus="{ record }">
          <div>
            <span>{{ record.transportType?.label + '：' }}</span>
            <span>{{ record.transStatus?.label }}</span>
          </div>
        </template>
        <template #documentaryDetails="{ record }">
          <div>
            <a-popover title="详细信息">
              <template #content>
                <p style="width:100px;padding: 0 10px;">{{ record.createTime }}</p>
                <p style="width:300px;padding: 0 10px;">{{ record.documentaryDetails }}</p>
                <p v-if="record.processContent" style="width:300px;padding: 0 10px;color:#d21165;">最新处理：{{ record.handler + " "+record.processTime + '：' + record.processContent }}</p>
              </template>
              <div style=display:flex>
                <div style="overflow:hidden;white-space:nowrap;text-overflow: ellipsis;">{{
                  record.createTime + '：'
                }}</div>
                <div style="width:400px;overflow:hidden;white-space:nowrap;text-overflow: ellipsis;">{{
                  record.documentaryDetails }}
                </div>
              </div>
              <div v-if="record.processContent">
                <div style="width:400px;overflow:hidden;color:#d21165;white-space:nowrap;text-overflow: ellipsis;">最新处理：{{
                    record.handler + record.processTime + '：' +record.processContent }}
                </div>
              </div>
            </a-popover>
          </div>
        </template>
        <template #documentaryType="{ record }">
          <div>
            <span v-show="record.documentaryType?.value === 1" style="color:#6B778C">{{ record.documentaryType?.label
            }}</span>
            <span v-show="record.documentaryType?.value === 2" style="color:#F59A23">{{ record.documentaryType?.label
            }}</span>
            <span v-show="record.documentaryType?.value === 3" style="color:#D9001B">{{ record.documentaryType?.label
            }}</span> 
            <span v-show="record.documentaryType?.value === 4" style="color:#b217f2">{{ record.documentaryType?.label
            }}</span>
          </div>
        </template>
        <template #operate="{ record }">
          <div>
            <a-space>
              <a @click="lookDetail(record)">查看</a>
            </a-space>
          </div>
        </template>
      </a-table>
    </div>
  </div>
  <a-modal v-model:visible="followShow" width="60%" footer="" :bodyStyle="{ padding: '5px' }">
    <template #title>
      <div>
        <h3>跟单-{{ vehicleDetail.vinNo }}</h3>
      </div>
    </template>
    <ResultFollow ref="endFollowRef" />
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { followAbnormalPage } from '@/api/transport/documentary'
import ResultFollow from '@/views/components/followOrder'

export default {
  components: {
    ResultFollow
  },
  setup () {
    const state = reactive({
      searchLoading: false,
      loading: false,
      followShow: false,
      searchForm: {
        pushStartTime: '',
        pushEndTime: '',
        documentaryStatus: null,
        documentaryType: null,
        orgId: null,
        transStatus: null,
        vinNo: ''
      },
      endFollowRef: ref(null),
      listData: [],
      truckStatus: [
        {
          label: '未安排',
          value: 1
        },
        {
          label: '已装车',
          value: 2
        },
        {
          label: '运输中',
          value: 3
        },
        {
          label: '中转待派',
          value: 4
        },
        {
          label: '已完成',
          value: 9
        }
      ],
      vehicleDetail: {},
      mirrorSearchForm: {},
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['5', '10', '15', '20'],
        pageSize: 15
      },
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '车牌车型',
          dataIndex: 'brand',
          align: 'center',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '订单号',
          dataIndex: 'orderId',
          align: 'center'
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
          width: '4%',
          align: 'center'
        },
        {
          title: '所属部门',
          width: '5%',
          dataIndex: 'orgName',
          align: 'center'
        },
        {
          title: '跟单详情',
          dataIndex: 'documentaryDetails',
          align: 'left',
          slots: {
            customRender: 'documentaryDetails'
          }
        },
        {
          title: '跟单类型',
          dataIndex: 'documentaryType',
          width: '6%',
          align: 'center',
          slots: {
            customRender: 'documentaryType'
          }
        },
        {
          title: '跟单时间',
          dataIndex: 'createTime',
          width: '5%',
          align: 'center'
        },
        {
          title: '异常状态',
          dataIndex: 'documentaryStatus.label',
          width: '5%',
          align: 'center'
        },
        {
          title: '发运状态',
          dataIndex: 'transStatus',
          width: '7%',
          align: 'center',
          slots: {
            customRender: 'transStatus'
          }
        },
        {
          title: '起运地',
          dataIndex: 'startAddress',
          align: 'center'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          align: 'center'
        },
        {
          title: '跟单记录',
          dataIndex: 'operate',
          width: '5%',
          align: 'center',
          slots: {
            customRender: 'operate'
          }
        },
      ]
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      loadData()
    })
    const loadData = () => {
      state.loading = true
      followAbnormalPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).finally(() => { state.loading = false })
    }
    const startChange = (e, v) => {
      state.searchForm.pushStartTime = v
    }
    const endChange = (e, v) => {
      state.searchForm.pushEndTime = v
    }
    const search = () => {
      state.pagination.current = 1
      loadData()
    }
    const reset = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      search()
    }
    const handleTableChange = page => {
      state.pagination = page
      loadData()
    }
    const lookDetail = record => {
      state.followShow = true
      state.vehicleDetail = record
      setTimeout(() => {
        state.endFollowRef.loadData(record.orderVehicleId)
      })
    }

    return {
      ...toRefs(state),
      search,
      reset,
      startChange,
      endChange,
      lookDetail,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
